import React from "react";
import GitHubCalendar from "react-github-calendar";
import { Row } from "react-bootstrap";

function Github() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
      <h1 className="project-heading" style={{ paddingBottom: "20px" }}>
        Days I Uploaded/Updated <strong className="purple">Public Repositories</strong>
      </h1>
      <h5 style={{ paddingBottom: "20px" }}>
        Majority of my Repositories are <strong className="purple">Private</strong> though! 🤫
      </h5>
      <GitHubCalendar
        username="kilam2468"
        blockSize={15}
        blockMargin={5}
        color="#c084f5"
        fontSize={16}
      />
    </Row>
  );
}

export default Github;
