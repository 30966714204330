import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub, BsFillRocketTakeoffFill } from "react-icons/bs";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ }}>
          {props.description}
        </Card.Text>
        {!props.isPrivate && props.demoLink && props.ghLink && (
          <Button variant="primary" href={props.ghLink} target="_blank">
            <BsGithub /> &nbsp;
            {props.isPrivate ? "Live" : "GitHub"}
          </Button>
        )}
        {"\n"}
        {"\n"}

        {/* If the component contains Demo link and if it's not Private then, it will render the below component  */}

        {!props.isPrivate && props.demoLink && (
          <Button
            variant="primary"
            href={props.demoLink}
            target="_blank"
            style={{ marginLeft: "10px" }}
          >
            <CgWebsite /> &nbsp;
            {"Demo"}
          </Button>
        )}

        {/* If the component is a private Repo and has a demoLink, it will render the below component  */}

        {props.isPrivate && props.demoLink && (
          <Button
          variant="primary"
          href={props.demoLink}
          target="_blank"
          style={{ marginLeft: "10px" }}
        >
          <BsFillRocketTakeoffFill /> &nbsp;
          {"Live"}
        </Button>
      )}

      {/* If the component is not a private repo, without a demoLink, but has a github link, it will render the below component*/}

      {!props.isPrivate && props.ghLink && !props.demoLink &&(
          <Button
          variant="primary"
          href={props.demoLink}
          target="_blank"
          style={{ marginLeft: "10px" }}
        >
          <BsGithub /> &nbsp;
          {"GitHub"}
        </Button>
      )}
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
