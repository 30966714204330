import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import twitterSC from "../../Assets/Projects/dankredditbot.png";
import LandFSC from "../../Assets/Projects/LandF.svg";
import twippylogo from "../../Assets/Projects/Twippy Logo -Name BlackBG.png";
import whitediscord from "../../Assets/Projects/discord-White.png";
import noahsite from "../../Assets/Projects/noahdetailing.png";
import aws from "../../Assets/Projects/aws.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={twitterSC}
              isPrivate={false}
              title="DankRedditBot"
              description="Twitter bot that uploads images from Subreddits to Twitter with titles and hashtags, ran on a AWS EC2"
              ghLink="https://github.com/kilam2468/Twitter-Reddit-Bot"
              demoLink="https://twitter.com/DankRedditBot"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={LandFSC}
              isPrivate={false}
              title="LandFApp"
              description="Small Lost and Found app with authentication for my school, built with Django"
              ghLink="https://github.com/kilam2468/LandFApp"
              //demoLink="https://landfapp-production.up.railway.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={twippylogo}
              isPrivate={true}
              title="Twippy"
              description="Discontinued Service that allowed users to have their Twitch clips automatically uploaded to their Youtube Channel"
              //ghLink=""
              //demoLink="https://twippy.tv/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={whitediscord}
              isPrivate={false}
              title="Basic Discord Bot with Database"
              description="A basic discord bot with a database to store user data, built with discord.py and MongoDB. It had Basic Commands, an Economy, a DND Module, Music VC Module, and Game Tracker for Fortnite and Apex"
              ghLink="https://github.com/kilam2468/Discord-Basic-Bot"
              //demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={noahsite}
              isPrivate={true}
              title="Placeholder Website for Friend's Bussiness"
              description="A proof of concept website created for my friends future car detailing bussiness. Built with Astro"
              //ghLink="https://noahdetailing.netlify.app"
              demoLink="https://noahdetailing.netlify.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={aws}
              isPrivate={false}
              title="AWS Systems Development"
              description="Currently creating Solutions for AWS Internal Systems as a Systems Development Engineer Intern"
              //ghLink="https://noahdetailing.netlify.app"
              //demoLink="https://www.linkedin.com/in/malik-michael/"
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
